.Sidebar{
    width: 100%;
    height: 100%;
    background-color: #1f1f1f9c;
    backdrop-filter: blur(5px);
    position: fixed;
    z-index: 998;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fade 0.7s ease-in-out;
}

@keyframes fade {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
.Sidebar .SidebarContainer{
    width: 80%;
    margin: auto;
}
.Sidebar .SidebarContainer ul{
    display: grid;
    align-items: center;
    justify-content: center;
    gap: 28px;
    margin-top: 28px;
    text-align: center;
}

.Sidebar .SidebarContainer ul li{
    text-decoration: none;
}

.Sidebar .SidebarContainer ul li{
    list-style: none;
}

.Sidebar .SidebarContainer ul li a{
    text-decoration: none;
    font-size: 1em;
    color: #fff;
    transition: 0.5s;
}

.Sidebar .SidebarContainer ul .active a, .Sidebar .SidebarContainer ul .active a:hover{
    color: #D9AC97 !important;
}
