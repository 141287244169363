.about{
    background-color: #EACABA;
    width: 100%;
    height: 100vh;
    max-height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.about .aboutContainer{
    width: 80%;
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    opacity: 0;
    transform: translateY(70px);
}

.aboutContainer .content{
    width: 45%;
}
.aboutContainer .aboutImage{
    width: 40%;
    min-width: 250px;
    display: flex;
}
.aboutContainer img{
    width: 100%;
    max-width: 300px;
    margin: auto;
}

.aboutContainer .content .title{
    color:#1F1F1F;
}

.aboutContainer .content p{
    font-size: 0.9em;
    font-weight: 500;
    letter-spacing: 1px;
    box-sizing: border-box;
    line-height: 30px;
    color: #585858;
}

@media screen and (max-width: 1200px){

    .about .aboutContainer{
        display: grid;
        gap: 25px;
    }
    .aboutContainer .content{
        text-align: center;
        width: 100%;
    }

    .about .aboutImage{
        width: 100%;
    }
    .aboutContainer .content p {
        font-size: 0.8em;
        font-weight: 400;
        line-height: 24px;
    }
}