

.WhatsappButton{
    width: 18px;
    height: 18px;
    position: fixed;
    background-color: #24CC63;
    z-index: 9999;
    opacity: 70%;
    bottom: 18px;
    right: 18px;
    padding: 18px;
    border-radius: 999px;
    color: #1f1f1f;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: 0.5s ease-in-out;
}

.WhatsappButton i{
    font-size: 1.3em;
}
.WhatsappButton:hover{
    opacity: 100%;
}