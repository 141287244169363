.hero{
    width: 100%;
    height: 100vh;
    max-height: 700px;
    background-blend-mode: overlay;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: background-image 1s cubic-bezier(0.55, 0.09, 0.68, 0.53);
}

.hero .heroContainer{
    width: 80%;
    margin-top: 90px;
    padding: 0 50px;
    box-sizing: border-box;
    display: grid;
    gap: 48px;
}

.hero .heroContainer h1{
    font-family: "Cinzel Decorative", serif;
    font-weight: 900;
    font-size: 4.5em;
    color: #D9AC97;
}

.hero .heroContainer h1 span{
    font-family: "Cinzel Decorative", serif;
    font-weight: 900;
    color: #fff;
}

.hero .heroContainer p{
    color: #fff;
    font-weight: 300;
    font-size: 1.1em;
}

@media screen and (max-width: 1200px){
    .hero .heroContainer{
        text-align: center;
        padding: 0;
    }
    .hero .heroContainer h1{
        font-size: 3em;
    }
    .hero .heroContainer p{
        font-size: 0.9em;
    }
    .button{
        display: flex;
        justify-content: center;
    }
}