.Contact{
    width: 100%;
    height: 100vh;
    max-height: 700px;
    background-color: #EACABA;
    box-sizing: border-box;
    position: relative;
}

.Contact .ContactContainer {
    width: 80%;
    height: 80%;
    margin: auto;
    box-sizing: border-box;
    opacity: 0;
    transform: translateY(70px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1f1f1f70;
    border-radius: 15px;
}
.Contact .title{
    color: #1F1F1F;
    text-align: center;
}

.ContactContainer .contactContent{
    width: 48%;
    padding: 28px;
    box-sizing: border-box;
    background-color: #EACABA;
    height: 90%;
    margin: 30px;
    border-radius: 15px;
    display: grid;
}

.contactContent .content h3{
    font-size: 1.3em;
    font-weight: 500;
}

.contactContent .content p{
    font-size: 0.9em;
    font-weight: 400;
    color: #585858;
    margin: 18px 0;
}

.contactContent .details ul li{
    list-style: none;
    margin: 20px 0;
    display: flex;
    align-items: center;
    color: #1F1F1F;
    gap: 18px;
}
.contactContent .details ul li i{
    width: 25px;
    font-size: 0.8em;
}
.contactContent .details ul li a {
    text-decoration: none;
    color: #1F1F1F;
    font-weight: 400;
    font-size: 0.8em;
}
.ContactContainer .contactForm{
    width: 48%;
    height: 80%;
    box-sizing: border-box;
    display: grid;
    gap: 20px;
    margin: auto;
    padding: 0 30px;
}
.contactForm input, .contactForm textarea{
    background-color: transparent;
    resize: none;
    border: none;
    border-bottom: 2px solid #eacaba77 ;
    color: #D9AC97;
    outline: none;
    font-size: 1em;
    font-weight: 500;
    padding: 10px 0;
}
.contactForm ::placeholder{
    color: #EACABA;
}
.contactForm textarea{
    height: 100px;
}
.contactForm p{
    color: #EACABA;
}
.contactForm .button a{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    float: right;
    color: #1F1F1F;
    font-weight: 400;
    background-color: #24CC63;
    transition: 0.7 ease;
}

.contactForm .button a:hover{
    background-color: #41ec83;
}

@media screen and (max-width: 1200px){
    .Contact{
        max-height: none !important;
        height: 100% !important;
        padding: 30px 0;

    }
    .Contact .ContactContainer{
        display: grid;
        background-color: transparent !important;
        width: 100%;
        height: 100%;
    }
    .ContactContainer .contactForm{
        width: 100% !important;
        height: 100%;
    }
    .ContactContainer .contactContent{
        width: 100%;
        margin: 0;
    }
    .contactForm input, .contactForm textarea{
        border-bottom: 2px solid #1f1f1f38 ;
        color: #1F1F1F;
    }
    .contactForm ::placeholder{
        color: #1F1F1F;
    }
    .contactForm p{
        color: #1F1F1F;
    }
}