.navbar{
    width: 80%;
    max-width: 1300px;
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    top: 30px;
    margin: auto;
    background-color: rgba(85, 84, 84, 0.267);
    backdrop-filter: blur(8px);
    border-radius: 999px;
    z-index: 999;
}

.navbar .navbarContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 30px 20px;
    width: 90%;
    margin: auto;
}
.navbar h1 a{
    font-size: 0.9em;
    color: #fff;
    font-family: "Cinzel Decorative", serif;
    font-weight: 700;
    text-decoration: none;
}
.navbar .menu i {
    cursor: pointer;
    color: #fff;
    padding: 13px;
    font-size: 1.3em;
    transition: 0.7s ease;
}
.navbar .menu i:hover{
    background-color: #ffffff49;
    border-radius: 999px;
}
.navbar .menu ul {
    display: flex;
    align-items: center;
    gap: 40px;
}

.navbar .menu ul li{
    list-style: none;
}

.navbar .menu ul li a{
    text-decoration: none;
    font-size: 1em;
    color: #fff;
    transition: 0.5s;
    font-weight: 200;
}
.navbar .menu ul .active a, .navbar .menu ul .active a:hover{
    color: #D9AC97 !important;
}

@media screen and (max-width: 1200px){
    .menu ul{
        display: none !important;
    }
    .navbar h1{
        font-size: 1.3em;
    }
    .navbar .menu i {
        font-size: 1em;
    }
    .navbar .navbarContainer{
        padding: 20px 20px;
    }
}

@media screen and (min-width: 1200px){
    .navbar .menu i {
        display: none;
    }
}    