.swipper{
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
}

.swipper ul{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    z-index: 10;
    margin: 0 50px;
}

.swipper ul li{
    list-style: none;
    padding: 3px;
    background: #B6B6B6;
    border-radius: 999px;
}

.swipper ul .active{
    background: #fff;
}

.swipper .chevrons{
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.swipper .chevrons i{
    font-size: 0.9em;
    padding: 10px;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #ffffff71 ;
    border-radius: 999px;
    transition: 0.3s;
}

.swipper .chevrons i:hover{
    background: #fff;
}