.Reviews{
    width: 100%;
    height: 100vh;
    max-height: 700px;
    background-color: #EACABA;
    box-sizing: border-box;
    position: relative;
}

.Reviews .ReviewsContainer {
    width: 80%;
    margin: auto;
    box-sizing: border-box;
    opacity: 0;
    transform: translateY(70px);
    display: flex;
    align-items: center;
}
.Reviews .title{
    color: #1F1F1F;
    text-align: center;
}

.ReviewsContainer .cards{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 5px;
    width: 100%;
    height: 100%;
}
.ReviewsContainer .cards .card{
    width: 27%;
    height:300px;
    background-color: #1F1F1F ;
    border-radius: 15px;
    background-blend-mode: overlay;
    background-position: center;
    display: grid;
    align-items: center;
    text-align: center;
    align-content: space-evenly;    
    padding: 17px 0;
    opacity: 70%;
}

.ReviewsContainer .cards .active{
    width: 33%;
    height: 370px;
    cursor: pointer;
    position: relative;
    background-color: #1F1F1F ;
    opacity: 100%;
}

.ReviewsContainer .cards .card h3{
    color: #EACABA;
    font-size: 0.8em;
    font-weight: 700;
}
.ReviewsContainer .cards .card p{
    color: #EACABA;
    font-size: 0.7em;
    font-weight: 300;
    padding: 35px;
    box-sizing: border-box;
    line-height: 27px;
    letter-spacing: 1px;
}
.ReviewsContainer .cards .active h3{
    color: #D9AC97;
    font-size: 1em;
}

@media screen and (max-width: 1200px){

    .ReviewsContainer .cards .card{
        display: none;
    }

    .ReviewsContainer .cards .active{
        display: grid;
        width: 100%;
        max-width: 500px;
    }
}