@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&display=swap');

*{
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  user-select: none;
  letter-spacing: 1px;
  scroll-behavior: smooth;
}


::-webkit-scrollbar {
  width: 3px;
}


::-webkit-scrollbar-track {
  box-shadow: inset 0 0 7px transparent;
}
 
::-webkit-scrollbar-thumb {
  background-color: #292727;
  /* outline: 1px solid slategrey; */
}

.title{
  font-size: 1.5em;
  font-family: Cinzel Decorative, serif ;
  font-weight: 700;
  padding: 30px 0;
}
body{
  max-width: 1513px;
  margin: auto;
  animation: fadeIn 1.7s;
}
@keyframes fadeIn {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}
.button a{
  padding: 13px 40px;
  background-color: #D9AC97;
  border-radius: 999px;
  text-decoration: none;
  color: #585858;
  font-size: 1em;
  font-weight: 500;
  transition: 0.5s;
  width: 20%;
  max-width: 70px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button a:hover{
  background-color: #EACABA;
}
.animate {
  opacity: 1;
  animation: fadeIn 1.7s forwards;
}
@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(70px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}