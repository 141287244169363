.Work{
    width: 100%;
    height: 100vh;
    max-height: 700px;
    background-color: #1F1F1F;
    box-sizing: border-box;
    position: relative;
}

.Work .WorkContainer {
    width: 80%;
    margin: auto;
    box-sizing: border-box;
    opacity: 0;
    transform: translateY(70px);
    display: flex;
    align-items: center;
}
.Work .title{
    color: #D9AC97;
    text-align: center;
}

.WorkContainer .cards{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 5px;
    width: 100%;
    height: 100%;
}
.WorkContainer .cards .card{
    position: relative;
    width: 27%;
    height:300px;
    background-color: #D9AC97;
    border-radius: 15px;
    background-blend-mode: overlay;
    background-position: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 17px 0;
    animation: slide 0.7s forwards;
}

.WorkContainer .cards .active{
    width: 33%;
    height: 370px;
    cursor: pointer;
    position: relative;
    opacity: 0;
}
@keyframes slide {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.cardContent{
    display: grid;
    justify-items: center
}
.button a{
    width: 100% !important;
    max-width: fit-content;
    margin: 10px 0;
    gap: 10px;
    padding: 11px 25px;
    font-size: 0.8em;
}

.WorkContainer .cards .active .hover{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.493);
    backdrop-filter: blur(2px);
    top: 0;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: hover 0.7s ease-in-out;
}

@keyframes hover {
    from{
        opacity: 0;
        width: 0;
        height: 0;
        border-radius: 999px;
        top: 50%;
    }
    to{
        opacity: 1;
        width: 100%;
        height: 100%;
        border-radius: 15px;
        top: 0;
    }
}

.WorkContainer .cards .active .hover a{
    text-decoration: none;
}

.WorkContainer .cards .active .hover i{
    font-size: 1.7em;
    transform: rotate(-40deg);
    padding: 15px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    color: #1F1F1F;
    border: 1px solid #1F1F1F;
    transition: 0.7s;
}
.active .hover i:hover{
    background-color: #58585879;
    color: #fff !important;
    border: 1px solid #58585879 !important;
}
.WorkContainer .cards .card h3{
    color: #EACABA;
    font-size: 0.8em;
    font-weight: 700;
    font-family: Cinzel Decorative, serif;
}

.WorkContainer .cards .active h3{
    color: #D9AC97;
    font-size: 1em;
}

@media screen and (max-width: 1200px){
    .WorkContainer .cards .card{
        display: none;
    }

    .WorkContainer .cards .active{
        display: flex;
        width: 100%;
        /* max-width: 700px; */
    }
}