.Details{
    width: 100%;
    height: 100%;
    background-color: #1F1F1F;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}

.Details .detailsContainer{
    width: 80%;
    margin-top: 150px;
    padding: 0 50px;
}
.Details .detailsContainer .title{
    color: #D9AC97;
}
.detailsContainer .detailsHeader{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #D9AC97;
    padding: 25px;
    gap: 25px;
    border-radius: 10px;
    box-sizing: border-box;
}

.detailsHeader .card{
    display: grid;
    gap: 10px;
    align-items: center;
    justify-content: center;
    justify-items: center;
    text-align: center;
}

.detailsHeader .card h2{
    font-size: 1em;
    color: #1F1F1F;
    font-weight: 600;
}
.detailsHeader .card h3{
    font-size: 0.8em;
    color: #1F1F1F;
    font-weight: 500;   
}

.detailsContainer .garelly{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 30px 0;
    /* gap: 5px; */
}

.garelly .card{
    width: 30%;
    /* max-width: 50%; */
    height: 300px;
    margin: 10px 0;
    box-sizing: border-box;
}
.garelly .active{
    width: calc(70% - 10px);
}
.garelly .card img{
    border-radius: 15px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width: 1200px){
    .garelly .card{
        width: 45%;
    }
    .garelly .active{
        width: 45%;
    }
}

@media screen and (max-width: 800px){
    .garelly .card{
        width: 100%;
    }
}