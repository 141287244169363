.not-found {
  width: 100%;
  height: 100vh;
  background-color: #D9AC97;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.not-found-container {
  text-align: center;
  width: 80%;
  margin: auto;
  box-sizing: border-box;
  opacity: 0;
  transform: translateY(70px);
  animation: slide 1.7s forwards;
}

.not-found h1 {
  color: #1F1F1F;
  font-family: 'Cinzel Decorative', serif;
  margin-bottom: 20px;
}

.not-found p {
  color: #1F1F1F;
  margin-bottom: 20px;
}

.not-found .button a {
  color: #1F1F1F;
  border: 1px solid #1F1F1F;
}

.not-found a:hover {
  background-color: #1F1F1F;
  color: #D9AC97;
}

@keyframes slide {
  from {
    opacity: 0;
    transform: translateY(70px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
