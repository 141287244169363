.PrivacyPolicy{
    width: 100%;
    min-height: 700px;
    padding: 80px 0;
    background-color: #1F1F1F;
    box-sizing: border-box;
    display: flex;
}

.PrivacyPolicy .PrivacyPolicyContainer{
    width: 80%;
    margin: auto;
    box-sizing: border-box;
    margin-top: 130px;
}

.PrivacyPolicyContainer .PrivacyPolicyHeader{
    width: 100%;
    border-radius: 15px;
    text-align: center;
    padding: 30px 15px;
    box-sizing: border-box;
}

.PrivacyPolicyHeader .title{
    color: #D9AC97;
    padding: 10px;
}

.PrivacyPolicyHeader p{
    color: #D9AC97;
    font-weight: 500;
    font-size: 0.8em;
}

.PrivacyPolicyContent p{
    color: #D9AC97;
    font-weight: 300;
    font-size: 0.8em;   
}

.PrivacyPolicyContent h2{
    font-size: 1.1em;
    font-weight: 500;
    color: #EACABA;
    margin: 30px 0;
}