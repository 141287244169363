.Faqs{
    width: 100%;
    height: 100vh;
    max-height: 700px;
    background-color: #1F1F1F;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 50px 0;
}

.Faqs .FaqsContainer{
    width: 80%;
    height: 100%;
    box-sizing: border-box;
    margin: auto;
    opacity: 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.FaqsContainer .faqsAside {
    width: 29%;
}
.FaqsContainer .faqsAside .title{
    color: #D9AC97;
}
.FaqsContainer .faqsAside p{
    color: #EACABA;
    font-size: 0.8em;
    font-weight: 400;
    opacity: 70%;
}
.FaqsContainer .faqsContent{
    width: 60%;
    height: calc(100% - 15px);
    box-sizing: border-box;
    overflow: hidden;
    padding: 30px;
    overflow-y: scroll;
    max-height: 490px;
}

.faqsContent .cards{
    display: grid;
    gap: 28px;
    padding-bottom: 10px;
}
.faqsContent .cards .card{
    width: 100%;
    height: 70px;
    overflow: hidden;
    border-bottom: 2px solid #d9ac978a;
}
.faqsContent .cards .active{
    height: auto !important;
    animation: openFaq 1.3s ease-in-out;
}


@keyframes openFaq {
    from{
        height: 50px;
        opacity: 0;
    }
    to{
        opacity: 1;
        height: auto;
    }
}
.faqsContent .cards .card .faqHeader{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 70px;
}
.card .faqHeader h3{
    font-size: 0.9em;
    font-weight: 500;
    color: #D9AC97;
}
.card .faqHeader i{
    font-weight: 700;
    font-size: 1.1em;
    padding: 5px;
    color: #D9AC97;
    cursor: pointer;
    transition: 0.3s;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card .faqHeader i:hover{
    background-color: #ffffff18;
    border-radius: 999px;
}

.card .faqContent p{
    font-size: 0.9em;
    color: #EACABA;
    opacity: 80%;
    padding-bottom: 18px;
}

@media screen and (max-width: 1200px){

    .Faqs .FaqsContainer{
        display: grid;
    }
    .FaqsContainer .faqsAside{
        width: 100%;
    }
    .FaqsContainer .faqsContent{
        width: 100%;
        padding: 30px 0;
    }
    .card .faqHeader h3{
        font-size: 0.9em;
    }
    .card .faqContent p{
        font-size: 0.8em;
    }
}