.Footer{
    width: 100%;
    height: 180px;
    background-color: #d9ac97ce;
    box-sizing: border-box;
    position: relative;
}

.Footer .FooterContainer {
    width: 80%;
    height: 100%;
    margin: auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
}

.FooterContainer .logo{
    width: 20%;
    height: 100%;
    overflow: hidden;
}

.FooterContainer .logo img{
    width: 180px;
    height: 180px;
}

.FooterContainer .content{
    width: 60%;
    text-align: center;
}
.content ul{
    list-style: none;
    display: flex;
    justify-content: center;
    gap:25px;
    margin: 20px 0;
}
.content ul li a{
    color: #1f1f1f;
    text-decoration: none;
    font-size: 0.9em;
    font-weight: 400;
}
.content p{
    font-size: 0.8em;
    color: #1f1f1f;
}
.content p a{
    color: #1f1f1f;
    font-weight: 400;
}
.FooterContainer .socialMedia{
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
}

.socialMedia a{
    color: #1f1f1f;
}

@media screen and (max-width: 1200px){
    .Footer {
        height: auto;
    }
    .Footer .FooterContainer{
        justify-content: center;
    }
    .FooterContainer .logo{
        width: 100%;
        display: flex;
    }
    .FooterContainer .logo img{
        margin: auto;
    }
    .Footer .FooterContainer {
        display: grid;
    }
    .FooterContainer .content{
        width: 100%;
    }
    .FooterContainer .socialMedia{
        justify-content: center;
        margin: 20px auto;
    }
    .FooterContainer .logo img{
        width: 230px;
        height: 230px;
    }
}